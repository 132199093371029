
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        






























































































.app-box-explorer {
  position: relative;

  &__box,
  &__box-explorer {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .hidden {
    visibility: hidden;
  }

  .be {
    .be-logo {
      display: none;
    }

    .be-header {
      background: $--clb-color-secondary__light;
      border: 1px solid $--clb-border-color-base;
      border-radius: $--clb-border-radius;
    }

    input {
      box-shadow: none !important;

      &:focus,
      &:active,
      &:hover {
        border-color: $--clb-color-primary !important;
        color: $--clb-color-primary !important;
      }
    }

    .be-empty div {
      display: none;
    }

    .bcpr-name {
      max-width: 275px;
    }

    .bcpr-btns .btn-plain.bcpr-btn {
      margin: 0;

      &:first-child {
        display: none;
      }
    }
  }

  @media (min-width: $--xs) {
    .be {
      .bcpr-name {
        max-width: none;
      }

      .bcpr-btns .btn-plain.bcpr-btn {
        margin: 0 10px 0 0;
      }
    }
  }
}
