
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        
































.app-box-explorer-skeleton {
  &__body {
    height: 50px;
    display: flex;
    border-bottom: 1px solid $--clb-skeleton-color;

    &-item {
      margin: auto auto auto 25px;
      display: flex;
      flex: 1 1 350px;

      svg {
        margin: 0;
      }
    }

    &-right {
      flex: 0 0 140px;
    }
  }
}
