
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        



















































































.campaign-setup-creatives {
  @include stage-layout;

  @apply tw-mt-0;

  .app-box-explorer {
    height: 600px;
  }
}
