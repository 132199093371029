// ===============================
// CLB-JUMBLEBERRY-VARIABLES-THEME
// ===============================

// ======== CLB-PRIMARY-COLORS ========
$--clb-color-primary: #5072ff !default;
$--clb-color-primary__dark: #4561ff !default;
$--clb-color-secondary__dark: #10236d !default;
$--clb-color-primary__light: #879bf8 !default;
$--clb-color-primary__lighter: #dee2ff !default;
$--clb-color-secondary__light: #f3f5fd !default;
$--clb-color-accent__dark: #303e4f !default;
$--clb-color-accent__light: #656e7a !default;

$--clb-color-grey__white-est: #fafbfc !default;
$--clb-color-grey__white-ter: #f4f6fc !default;
$--clb-color-grey__grey-lighter: #e7e9f0 !default;
$--clb-color-grey__grey-light: #cccfdb !default;
$--clb-color-grey__grey: #a0a4b7 !default;
$--clb-color-grey__grey-dark: #7c8196 !default;
$--clb-color-grey__grey-darker: #5d606f !default;

$--clb-color-grey__blue-grey-light: #d1d8dc !default;

$--clb-color-pink: #f35c90;
$--clb-color-primary__white: #fff !default;
$--clb-color-primary__black: #000 !default;

$--clb-color__headings: #262626 !default;

// ======== CLB-COLORS ========
$--clb-color-success: $--clb-color-primary !default;
$--clb-color-warning: #e6a23c !default;
$--clb-color-danger: #f56c6c !default;
$--clb-color-info: #909399 !default;
$--clb-color-green: #0ca678 !default;
$--clb-color-green-darker: #087f5b !default;
$--clb-color-red: #f03e3e !default;
$--clb-color-red-darker: #c92a2a !default;

$--clb-color-hover-background: #f4f6fc !default;
$--clb-color-selected-background:#eef1ff !default;
$--clb-color-error-background: #fff3ef !default;
$--clb-color-error-text: #bb200c !default;

$--clb-bg-accent-color: $--clb-color-secondary__light !default;
$--clb-body-font: #525662;
$--clb-shadow-color: rgba(28, 24, 7, 0.15) !default;
$--clb-drop-shadow: 0 14px 27px -20px !default;
$--clb-disabled-color: $--clb-color-grey__grey-lighter !default;
$--clb-camp-details-standards-color: #626262 !default;
$--clb-light-bg-color: #f3f5ff;
$--clb-skeleton-color: #efefef;
$--clb-node-color: #cccfdb;
$--clb-node-line-color: #dcdfe8;

// ======== CLB-FONTS ========
$--clb-font-path: "~@/theme/clb/fonts" !default;

$--clb-font-size-xl: 20px !default;
$--clb-font-size-lg: 18px !default;
$--clb-font-size-base: 16px !default;
$--clb-font-size-sm: 14px !default;
$--clb-font-size-xs: 12px !default;

$--clb-h1__font-size: 32px !default;
$--clb-h2__font-size: 28px !default;
$--clb-h3__font-size: 24px !default;
$--clb-h4__font-size: $--clb-font-size-xl !default;
$--clb-h5__font-size: $--clb-font-size-lg !default;
$--clb-h6__font-size: $--clb-font-size-base !default;

// ======== CLB-WEIGHTS ========
$--clb-font-weight__lighter:         lighter !default;
$--clb-font-weight__light:           300 !default;
$--clb-font-weight__normal:          400 !default;
$--clb-font-weight__medium:          500 !default;
$--clb-font-weight__semi-bold:       600 !default;
$--clb-font-weight__bold:            700 !default;
$--clb-font-weight__bolder:          bolder !default;

// ======== CLB-LINE-HEIGHT ========
$--clb-base__line-height: 1.4 !default;
$--clb-p__line-height: 1.4 !default;
$--clb-h1__line-height: 42px !default;
$--clb-h2__line-height: 36px !default;
$--clb-h3__line-height: 32px !default;
$--clb-h4__line-height: 28px !default;
$--clb-h5__line-height: 24px !default;
$--clb-h6__line-height: 20px !default;

// ======== CLB-LINE-HEIGHT ========
$--clb-z-index-app-header: 1999;
$--clb-z-index-left-sidebar: 1900;

// ======== CLB-BUTTON ========
$--clb-btn-border-none: none !default;
$--clb-btn-bg-color: $--clb-color-primary !default;
$--clb-btn-width: auto !default;

// ======== LINK-STATES ========
$--clb-link-color: $--clb-color-secondary__dark !default;
$--clb-active: $--clb-color-secondary__dark !default;
$--clb-hover: underline !default;
$--clb-hover-color: $--clb-color-secondary__dark !default;
$--clb-visited: $--clb-color-secondary__dark !default;

// ======== WIDTH-SIZES ========
$--clb-width-100: 100% !default;

// ======== BORDER ========
$--clb-border-radius: 4px !default;
$--clb-border-color-lighter: #ebeef5 !default;
$--clb-border-color-base: #e6e6e6 !default;
$--clb-border-color-dark: #c1c1c1 !default;
$--clb-border-complete-light: 1px solid $--clb-border-color-lighter;
$--clb-border-complete-dark: 1px solid $--clb-color-grey__blue-grey-light;
$--clb-border-divider: 2px solid #cfd8dc; // Use-case: Any design that calls for a dividing line or stylized separator
$--clb-border-divider-1px: 1px solid #cfd8dc; // Use-case: Any design that calls for a dividing line or stylized separator

// ============ SPACING =================

// ======== (MICRO) ========
$--clb-space-1: 4px !default;
$--clb-space-2: 8px !default;
$--clb-space-3: 12px !default;
$--clb-space-4: 16px !default;
$--clb-space-5: 20px !default;
$--clb-space-6: 24px !default;

// ======== LAYOUT(MACRO) ========
$--clb-layout-1: 16px !default;
$--clb-layout-2: 24px !default;
$--clb-layout-3: 32px !default;
$--clb-layout-4: 48px !default;
$--clb-layout-5: 64px !default;
$--clb-layout-6: 96px !default;

// ======== APP LAYOUT ========
$--clb-app-content-width: 1068px;
$--clb-app-header-height: 60px;
$--clb-app-footer-height: 200px;
$--clb-app-left-sidebar-width-collapsed: 0;
$--clb-app-left-sidebar-menu-width: 256px;
$--clb-app-left-sidebar-width: $--clb-app-left-sidebar-menu-width;
$--clb-app-right-sidebar-menu-width: 600px;

// ======== MOBILE LAYOUT ========
$--clb-mobile-padding: $--clb-space-4;

// ======== TRANSITION ===========
$--clb-hover-transition: 300ms cubic-bezier(0.43, 0.41, 0.22, 0.91);
// ======== TRANSITION ===========

// ======== MAIN-HOVER ===========
$--clb-no-shadow-hover: none !default;
$--clb-transform-hover: translate(4px) !default;
// ======== /MAIN-HOVER ===========

// ======== /ANIMATION-NAMES ===========
$--clb-leaving-pop-animation: leaving-pop !default;
$--clb-entering-pop-animation: entering-pop !default;
$--clb-cubic-bezier: cubic-bezier(0.72, -0.61, 0.25, 1.51) !default;
$--clb-transition-time: 300ms !default;
$--clb-transition-delay: 0.25s !default;
$--clb-transition-step: ease !default;
// ======== /ANIMATION-NAMES ===========

// ============ BREAK-POINTS =================
$--xs: 450px !default;
$--sm: 768px !default;
$--md: 992px !default;
$--lg: 1200px !default;
$--xl: 1920px !default;
// ============ /BREAK-POINTS =================

// ============ PERFORMANCE_METRICS_VISUALIZATION =================
// SKELETON HEIGHT CALCULATIONS: (ChartWidth / AspectRatio) + ListHeight
$--clb-pmv-chart-ratio: calc(16 / 9);
$--clb-pmv-chart-width-xs: calc(100vw - (#{$--clb-mobile-padding} * 2));
$--clb-pmv-chart-height-xs: $--clb-pmv-chart-width-xs / $--clb-pmv-chart-ratio;
$--clb-pmv-list-height-xs: 120px;
$--clb-pmv-list-height-sm: 140px;
// ============ /PERFORMANCE_METRICS_VISUALIZATION =================
